import React, { useState, useEffect } from "react";
import "./banner.css";
import styled from "styled-components";

const Banner = () => {
  // Generate an array of logo imports dynamically
  const generateLogos = () => {
    let logos = [];
    for (let i = 1; i <= 21; i++) {
      const logoNumber = i.toString().padStart(2, "0"); // Ensure two digits
      logos.push(require(`../../assets/BrandsLogo/${logoNumber}.png`));
    }
    return logos;
  };

  const allLogos = generateLogos();

  // Divide logos into three parts
  const firstSet = [
    ...allLogos.slice(0, 7),
    ...allLogos.slice(0, 7),
    ...allLogos.slice(0, 7),
    ...allLogos.slice(0, 7),
  ];
  const secondSet = [
    ...allLogos.slice(7, 14),
    ...allLogos.slice(7, 14),
    ...allLogos.slice(7, 14),
    ...allLogos.slice(7, 14),
  ];
  const thirdSet = [
    ...allLogos.slice(14, 21),
    ...allLogos.slice(14, 21),
    ...allLogos.slice(14, 21),
    ...allLogos.slice(14, 21),
  ];

  const [logos, setLogos] = useState(firstSet); // Initially show first set

  // Handles the animation end event for the first logo only
  const handleAnimationEnd = () => {
    setLogos((currentLogos) => {
      const movedLogo = currentLogos.shift(); // Remove the first logo
      return [...currentLogos, movedLogo]; // Append it to the end
    });
  };

  return (
    <div id="client-section" className="disable-select">
      <TextContainer>
        <h1 className="h2 expertise-text white-text">
          {/* <span className="small-expertise">Our</span> */}
          {/* <br />{" "} */}

          <span className="big-expertise" >
            Brands we helped to <span className="brand-color">shine</span>
          </span>
        </h1>

        <div className="line-expertise"></div>
      </TextContainer>
      <div className="items-center">
        <div className="banner">
          <div className="frost left-frost"></div>
          <div className="frost right-frost"></div>
          <div className="logo-container">
            <div className="horizontal-list scroll-left-direction">
              {logos.map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Logo ${index}`}
                  className="logo"
                  onAnimationEnd={index === 0 ? handleAnimationEnd : undefined} // Only the first logo triggers the cycle
                />
              ))}
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="frost left-frost"></div>
          <div className="frost right-frost"></div>
          <div className="logo-container">
            <div className="horizontal-list second-list-logos scroll-right-direction">
              {secondSet.map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Logo ${index}`}
                  className="logo"
                  onAnimationEnd={index === 0 ? handleAnimationEnd : undefined}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="frost left-frost"></div>
          <div className="frost right-frost"></div>
          <div className="logo-container">
            <div className="horizontal-list scroll-left-direction">
              {thirdSet.map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Logo ${index}`}
                  className="logo"
                  onAnimationEnd={index === 0 ? handleAnimationEnd : undefined}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TextContainer = styled.div`
  text-align: center;
  // margin-bottom: 2.187em;
  // width: 75%;
  padding-bottom: 20px;
  // margin: 0 12.5%;
  margin: 0 auto 2.187em auto;
    width: 90vw;

  .h2 {
    font-size: 45px;
    color: #f4f3ef;
  }

  .small-expertise {
    font-size: 0.5em;
  }

  .big-expertise {
    font-weight: 700;
    font-size: 1.5em;
  }
  @media only screen and (max-width: 480px) {
    .big-expertise {
      font-size: 10vw;
    }
  }
`;

export default Banner;
