import React from "react";
import { Container, Typography, Grid, IconButton, Button } from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  WHITE_COLOR,
  BLACK_COLOR,
  PRIMARY_COLOR,
} from "../../constants/colors";
import "./footer.css";
import { MAIN_FONT } from "../../constants/fonts";

const Footer = ({handleTalkButton}) => {
  return (
    <footer style={{ color: "#f4f3ef" }}>
      <div className="footerLogoContainer disable-select">
        <img
          src={require("../../assets/logo.webp")}
          loading="lazy"
          width="114"
          alt="Logo"
          className="navbar-logo-image footerLogo"
        />
      </div>
      <Container>
        <Typography
          variant="h5"
          gutterBottom
          className="footer-heading disable-select"
          style={{
            fontSize: 45,
            fontWeight: "bold",
            textAlign: "left",
            // fontFamily: "Poppins, sans-serif",
          }}
        >
          Let’s embark on the journey! Shall we?
        </Typography>
        <div
          className="footer-info-container"
          container
          spacing={3}
          style={{ marginTop: 15 }}
        >
          <div item xs={12} sm={4}>
            <Typography
              variant="h6"
              gutterBottom
              style={{
                fontSize: 25,
                marginBottom: 10,
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              <span className="brand-color disable-select">Where to reach us.</span>
            </Typography>

            <div className="info-flex">
              <div className="info-flex-in">
                <img
                  src={require("../../assets/mail.png")}
                  alt="Email Icon"
                  width="20"
                  height="20"
                  className="invertImage"
                />
                <span>info@wavelaps.com</span>
              </div>

              <div className="info-flex-in">
                <img
                  src={require("../../assets/telephone.png")}
                  alt="Telephone Icon"
                  width="20"
                  height="20"
                  className="invertImage"
                />
                <span>+91-9354031490</span>
              </div>

              {/* <Typography
                variant="body2"
                style={{
                  fontSize: 20,
                  fontWeight: "100",
                  textAlign: "left",
                  cursor: "pointer",
                }}
                className="hoverable"
              >
                Address: BE-8, Upper Ground Floor, <br />
                Hari Nagar, New Delhi, India
              </Typography> */}
            </div>
          </div>

          <div item xs={12} sm={4}>
            <Typography
              variant="h6"
              gutterBottom
              className="disable-select"
              style={{
                fontSize: 25,
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Next steps
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "50%",
                borderRadius: 20,
              }}
            >
              {/* <button className="button-main footer-button" id="button-6">
                <div id="spin"></div>
                <a id="head-cta-get-in-touch" href="/contact">
                  Post-launch
                </a>
              </button> */}
              <button className="button-main footer-button" id="button-6" onClick={handleTalkButton}>
                <div id="spin"></div>
                <a id="head-cta-get-in-touch" href="/contact">
                  Get in touch
                </a>
              </button>
              {/* <button className="button-main footer-button" id="button-6">
                <div id="spin"></div>
                <a id="head-cta-get-in-touch" href="/contact">
                  Portfolio
                </a>
              </button> */}
            </div>
          </div>
        </div>

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{
            justifyContent: "space-evenly",
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          {/* <Grid item>
            <IconButton style={{ color: "#fff" }}>
              <Facebook fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton style={{ color: "#fff" }}>
              <Twitter fontSize="large" />
            </IconButton>
          </Grid> */}
          <Grid item>
            <IconButton style={{ color: "#fff" }} href="https://www.instagram.com/wavelaps_technologies?igsh=eWptcWN6YXR3OXpv">
              <Instagram fontSize="large" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton style={{ color: "#fff" }} href="https://www.linkedin.com/company/wavelaps/?viewAsMember=true">
              <LinkedIn fontSize="large" />
            </IconButton>
          </Grid>
          
        </Grid>
        <Grid item className="centerthis">
            <a
              href="https://api.whatsapp.com/send?phone=9354031490&text=Hi! I would like to know more information about Wavelaps."
              className="float"
              target="_blank"
            >
              <IconButton style={{ color: "#fff" }} className="my-float">
                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
              </IconButton>
            </a>
          </Grid>
        {/* <Typography
          variant="body2"
          align="center"
          style={{ marginTop: "20px", fontFamily: "Poppins, sans-serif" }}
        >
          &copy; 2024 arfected. All Rights Reserved.
        </Typography> */}
      </Container>
    </footer>
  );
};

export default Footer;
