import React from "react";
import "./trade.css"; // Import your CSS file for styling
import { BLUE_COLOR, PRIMARY_COLOR, BLACK_COLOR } from "../../constants/colors";

const YourComponent = () => {
  // List of different names
  const differentNames = [
    "Social AR",
    "Virtual Production",
    "Digital Production",
    
    "360 Video Production ",
    "3D Design & Modeling",
    "Experiential",
    "Creative Development",
    "Custom App Development",
    "Immersive Web",
    "QA Testing",
    "Commercial Video Production",
    "Art Direction",
    "Web AR",
    "UX Design",
    "Mobile AR",
    "Photogrammetry",
    "Spatial Web",
    "AR Advertising",
    "Virtual Showrooms",
    "NFT Strategy",
    "Realtime VFX",
    "Wireframes & User Journeys",
    "Rapid Prototyping",
    "Virtual Events",
    "Emerging Tech R&D",
    "Post Production",
    "Game Design & Development",
    "Custom AR Filters",
    "Immersive Training",
    "Snapchat Lenses",
    "Virtual Try-Ons",
    "TikTok Branded Effects",
    "Product Visualizations",
    "Immersive Shopping Experiences",
    "Brand Ideation Sessions",
    "Virtual Reality Development",
    "Software Development",
    "Concepting & Treatments",
    "Unity & Unreal development",
  ];
  const highlightNames = [
    "Immersive Web",
    "3D Design & Modeling",
    "Social AR",
    "Web AR",
    "AR Advertising",
    "Custom AR Filters",
    "Virtual Try-Ons",
    "Unity & Unreal development",
  ];

  // Mapping of names to colors
  const nameColors = {};
  differentNames.forEach((name) => {
    nameColors[name] = highlightNames.includes(name)
      ? PRIMARY_COLOR
      : BLACK_COLOR;
  });

  return (
    <div className="your-component">
      {/* <h1 className="titlef" style={{ "--white": BLACK_COLOR }}>
        Tools Of <span style={{ "--primary": PRIMARY_COLOR }}>The</span> Trade
      </h1> */}
      <span className="titlef disable-select">
        Our industry’s <span className="brand-color">jargons</span>
      </span>
      <div className="boxes-container">
        {differentNames.map((name, index) => (
          <div
            key={index}
            className={`box ${
              PRIMARY_COLOR === nameColors[name] ? "glow-on-hover" : "disable-select"
            }`}
            style={{ color: nameColors[name] }}
          >
            <div className="box-name">{name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YourComponent;
