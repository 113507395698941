import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "./videoplayer.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useInView } from 'react-intersection-observer';

const videos = [
  {
    id: 1,
    title: "Video 1",
    url: require("../../assets/videos/video1.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover1.png"),
  },
  {
    id: 2,
    title: "Video 2",
    url: require("../../assets/videos/video2.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover2.png"),
  },
  {
    id: 3,
    title: "Video 3",
    url: require("../../assets/videos/video3.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover3.png"),
  },
  {
    id: 4,
    title: "Video 4",
    url: require("../../assets/videos/video4.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover4.png"),
  },
  {
    id: 5,
    title: "Video 5",
    url: require("../../assets/videos/video5.webm"),
    thumbnail: require("../../assets/videosThumbnail/cover5.png"),
  }
];

const VideoCarousel = () => {
  const [currentVideo, setCurrentVideo] = useState(videos[0]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [userInteracted, setUserInteracted] = useState(false);
  const videoRefs = useRef([]);
  const sliderRef = useRef(null);
  const thumbnailContainerRef = useRef(null);
  var videoRefIndex = 0;

  const { ref, inView } = useInView({
    triggerOnce: false, 
    threshold: 0.1,    
  });

  const handleThumbnailClick = (video) => {
    setCurrentVideo(video);
    
    const videoIndex = videos.findIndex((v) => v.id === video.id);
    sliderRef.current.slickGoTo(videoIndex);
    // afterChangeHandle(videoIndex)
  };

  useEffect(()=>{
    if (inView) {
      if(window.innerWidth < 480){
        if(videoRefs.current[currentVideoIndex+2].paused){
          videoRefs.current[currentVideoIndex+2].muted = !userInteracted;
          videoRefs.current[currentVideoIndex+2].play();
          videoRefs.current[currentVideoIndex+2].classList.add("slick-current");
          thumbnailContainerRef.current?.firstChild.classList.add('thumbnail-active')

        }
        
      }
      else{
        if(videoRefs.current[currentVideoIndex+4].paused){
          videoRefs.current[currentVideoIndex+4].muted = !userInteracted;
          videoRefs.current[currentVideoIndex+4].play();
          videoRefs.current[currentVideoIndex+4].classList.add("slick-current");
          thumbnailContainerRef.current?.firstChild.classList.add('thumbnail-active')
        }
      }
    }
    else{
      console.log("pausing video");
      if(window.innerWidth < 480){
        if(!videoRefs.current[currentVideoIndex+2].paused){
          videoRefs.current[currentVideoIndex+2].pause();

        }
        
      }
      else{
        console.log("pausing  video 1");
        if(!videoRefs.current[currentVideoIndex+4].paused){
          console.log("pausing video 2");
          videoRefs.current[currentVideoIndex+4].pause();
        }
      }
    }
  },[inView]);

  // const afterChangeHandle = (current) => {
  //   console.log("changing", videoRefs.current[current+4]);
  //   setCurrentVideo(videos[current]);
    
  //   videoRefs.current.forEach((videoRef, index) => {
  //     // console.log("all", videoRef);
  //     if (videos[index] !== currentVideo && videoRef) {
  //       // console.log(videoRef);
  //       videoRef.currentTime = 0;
  //       videoRef.pause();
        
  //     }
      
  //   });
  //   if(window.innerWidth < 480){
  //     if(videoRefs.current[current+2].paused){
  //       videoRefs.current[current+2].muted = false;
  //       videoRefs.current[current+2].currentTime = 0;
  //       videoRefs.current[current+2].play();
  //     }
  //   }
  //   else{
  //     if(videoRefs.current[current+4].paused){
  //       videoRefs.current[current+4].muted = false;
  //       videoRefs.current[current+4].currentTime = 0;
  //       videoRefs.current[current+4].play();
  //     }
  //   }
    
  // }
  const StoreVideoRef = (el) =>{
    videoRefs.current[videoRefIndex] = el;
    videoRefIndex++;
  }



  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 850, // Screen width of 480px or below
        settings: {
          slidesToShow: 1, // Change slidesToShow to 1
        },
      }
      
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCurrentVideoIndex(newIndex);
      if(!userInteracted){
        setUserInteracted(true);
      }
      if(window.innerWidth < 480){
        videoRefs.current[oldIndex+2].classList.remove("slick-current");
        videoRefs.current[newIndex+2].classList.add("slick-current");
        videoRefs.current[oldIndex+2].currentTime = 0;
        videoRefs.current[oldIndex+2].pause();
        thumbnailContainerRef.current?.children[oldIndex].classList.remove('thumbnail-active')
        if(videoRefs.current[newIndex+2].paused){
          videoRefs.current[newIndex+2].muted = false;
          videoRefs.current[newIndex+2].currentTime = 0;
          videoRefs.current[newIndex+2].play();
          thumbnailContainerRef.current?.children[newIndex].classList.add('thumbnail-active')
        }
      }
      else{
        videoRefs.current[oldIndex+4].classList.remove("slick-current");
        videoRefs.current[newIndex+4].classList.add("slick-current");
        videoRefs.current[oldIndex+4].currentTime = 0;
        videoRefs.current[oldIndex+4].pause();
        thumbnailContainerRef.current?.children[oldIndex].classList.remove('thumbnail-active')
        if(videoRefs.current[newIndex+4].paused){
          videoRefs.current[newIndex+4].muted = false;
          videoRefs.current[newIndex+4].currentTime = 0;
          videoRefs.current[newIndex+4].play();
          thumbnailContainerRef.current?.children[newIndex].classList.add('thumbnail-active')
        }
      }
      

    },
  };

  return (
    <CarouselContainer ref={ref}>
      <TextContainer>
        <h1 className="h2 expertise-text white-text">
          {/* <span className="small-expertise">Our</span> <br />{" "} */}
          <span className="big-expertise disable-select">
            Are we <span className="brand-color">worth</span> your trust?
          </span>
        </h1>
        <div className="line-expertise"></div>
      </TextContainer>
      <SliderContainer>
        <Slider ref={sliderRef} {...settings} >
          {videos.map((video, index) => (
            <VideoWrapper key={video.id} >
              <StyledVideo
                ref={(el) => StoreVideoRef(el)}
                src={video.url}
                playsInline
                loop
                controls
              />
            </VideoWrapper>
          ))}
        </Slider>
      </SliderContainer>
      <ThumbnailContainer ref={thumbnailContainerRef}>
        {videos.map((video) => (
          <Thumbnail
            key={video.id}
            onClick={() => handleThumbnailClick(video)}
          >
            <ThumbnailImage src={video.thumbnail} alt={video.title} />
          </Thumbnail>
        ))}
      </ThumbnailContainer>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  width: 100%;
  // margin: auto;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 2.187em;
  margin: 0 auto 2.187em auto;
  width: 90vw;
  .h2 {
    font-size: 45px;
    color: #f4f3ef;
  }

  .small-expertise {
    font-size: 0.5em;
  }

  .big-expertise {
    font-weight: 700;
    font-size: 1.5em;
  }
  @media only screen and (max-width: 480px) {
    .big-expertise {
      font-size: 10vw;
    }
  }
`;

const SliderContainer = styled.div`
  height: 600px;
  margin-bottom: 5px;
  @media only screen and (max-width: 1024px) {
    height: 65vw;
    width: 100vw;
  }
  @media only screen and (max-width: 850px) {
    height: 100vw;
  }
  @media only screen and (max-width: 480px) {
    height: 100vw;
  }
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
`;

const StyledVideo = styled.video`
  border-radius: 15px;
  height: 450px;
  filter: blur(5px);
  transition: all 0.3s ease;
  &.slick-current {
    box-shadow: 0px 0px 15px -3px #fcbe0e;
    height: 550px;
    filter: none;
  }

  @media only screen and (max-width:1024px){
    height:47vw;
    &.slick-current {
      height: 49vw;
    }
  }
    
  @media only screen and (max-width:850px){
    height:90vw;
    &.slick-current {
      height: 100vw;
    }
  }

  @media only screen and (max-width:480px){
    height:90vw;
    &.slick-current {
      height: 100vw;
    }
  }
`;

const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Thumbnail = styled.div`
  margin: 0 10px;
  cursor: pointer;
  border-radius: 15px;

  &.thumbnail-active{
    box-shadow: 0px 0px 15px -3px #fcbe0e;
  }
`;

const ThumbnailImage = styled.img`
  width: 100px;
  height: 75px;
  object-fit: cover;
  border-radius: 15px;
  @media only screen and (max-width:480px){
    width: 15vw;
    height: 11.27vw;
  }
`;

export default VideoCarousel;
