import React, { useEffect, useState } from "react";
import "./App.css";
import ImagePreloader from './components/preloading/preload.js';
import Home from "./pages/homepage.js";
import emailjs from "@emailjs/browser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

  const [loadingComplete, setLoadingComplete] = useState(false);

  const handlePreloadComplete = () => {
    setLoadingComplete(true);  // Update state when preloading is complete
  };


  useEffect(() => {
    emailjs.init("86B8Qnvi0Fat7YQcG");
  }, []);
  return (
    <div>
      <ImagePreloader onPreloadComplete={handlePreloadComplete} />
      {loadingComplete && <Home />}
    </div>
  );
}

export default App;
