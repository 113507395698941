import React, { useState } from "react";
import "./signup.css";
import axios from "axios";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../../constants/colors";
import { width } from "@mui/system";

const SignupForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('FullName',name);
    data.append('Email',email);
    const action = "https://script.google.com/macros/s/AKfycbyBA0HMTdDiYKx4NDDC9sHY74rpzFks701qtK1yokUeT066l31xwdAMQZoSa-0Emo8ZFA/exec";
      fetch(action, {
        method: 'POST',
        body: data,
      })
      .then((response ) => {
        console.log("Success!",response);
        
      })
    // axios
    //   .post(
    //     "https://sheet.best/api/sheets/9acca5c9-47b3-43ff-a010-022db06b5f4a",
    //     data
    //   )
    //   .then((response) => {
    //     setName("");
    //     setEmail("");
    //     alert("Thank you for signing up!"); // Show alert message
    //   })
    //   .catch((error) => {
    //     alert("Oops! Something went wrong. Please try again."); // Show alert message
    //   });
  };

  return (
    <div className="signup-container">
      <div className="title disable-select">
        Dropping some remarkable insights exclusively for you!
      </div>
      {/* <div className="subtitle">
      </div> */}
      <form onSubmit={handleSubmit} >
        <div className="input-container">
          <input
            type="text"
            className="input-field"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            className="input-field"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* <button
            type="submit"
            className="submit-button"
            style={{
              "--primary": PRIMARY_COLOR,
              "--white": WHITE_COLOR,
              "--black": BLACK_COLOR,
            }}
          >
            Submit
          </button> */}
          <button type="submit" class="color-black" id="button-7">
            <div id="spin"></div>
            <a id="head-cta-get-in-touch">
              Submit
            </a>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
